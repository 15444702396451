import { Box, Grid } from "@mui/material";
import React from "react";
import SidebarContainer from "../Sidebar/SidebarContainer";
import HeaderContainer from "../Header/Header";
import FooterContainer from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import { USER_ROLE } from "../../../core/Constant"


const Layout = ({ userRole }) => {
 

    return (
        <Box className="layout-main-container">
            <SidebarContainer type={localStorage.getItem('userType') == USER_ROLE.admin ? "admin" : "student"} />
            <Box sx={{ width: 1 }} className="layout-inner-content">
                <HeaderContainer />
                <Outlet />
                {/* <Box pt={2.5} pb={2.5} pl={1} pr={1}>
                    <Outlet />
                </Box> */}
                {/* <Grid spacing={2} container maxWidth={"xl"}>
                <Grid spacing={2} container>
                    <Grid className="outer-box-all" item>
                        <Outlet />
                    </Grid>
                </Grid> */}
                <FooterContainer />
            </Box>
        </Box>
    )
}



export default Layout;