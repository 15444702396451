import { Box } from "@mui/material";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerComponent = (props) => {
   const {label, isError, error} = props;
  
return(<Box mb={1} ml={1} mr={2}>
    <p style={{padding:0, margin:0, color:"#4E657C", fontSize:19, fontWeight:500}}>{label}</p>
    <DatePicker className=" form-control " {...props} />
    {isError ? <p className="input-error-text">{error}</p> : null}
</Box>);
}

export default DatePickerComponent;