import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import HeadingComponent from "../../../component/atom/Headings/Heading";
import { Box } from "@mui/material";
import {
  DialogComponent,
  IconButtonComponent,
  SearchBarComponent,
  TextIconButtonComponent,
} from "../../../component/atom";
import DropDownComponent from "../../../component/atom/Inputs/DropDown";
import { CreateForumForm, MainForumCard } from "../../../component/molecule";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";
import { getText } from "../../../core/Constant";
import { setTopLevelNavigator } from "../../../core/services/NavigationServicd";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const ForumMainScreen = ({
  forumList,
  getForumList,
  joinForum,
  verifyToken,
}) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    setList(forumList);
  }, [forumList]);

  useEffect(() => {
    getForumList();
  }, []);

  const navigateToForumMessage = (item) => {
    // 👇️ navigate to /contacts
    navigate("/forum-message", {
      state: { forumId: _.get(item, "id", ""), forum: item },
    });
  };

  const onChange = (e) => {
    const text = getText(e);
    getForumList({ search_text: text });
    setText(text);
  };

  return (
    <>
      <Box className="main-screen-container">
        <Box mt={1}>
          <HeadingComponent
            text={"Forum"}
            fontweigth={600}
            size={26}
            fontfamily={"Montserrat"}
          />
        </Box>
        <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt:3.5,
            mb:2.5
          }}
        >
          <SearchBarComponent value={text} onchange={onChange} />
          <TextIconButtonComponent btnText={"Create a forum"} icon={faPlus} onclick={()=> setOpen(true)}/>
        </Box>
       
        {list.map((item, index) => {
          return (
            <MainForumCard
              onJoinForm={() => {
                joinForum({ forum_id: item.id });
              }}
              item={item}
              key={`std_forum-${index}_number`}
              onForumView={() => navigateToForumMessage(item)}
            />
          );
        })}
        </Box>
       
        
      </Box>
      <DialogComponent
        isShowCloseButton={false}
        title={
          <span className="modalTitle modalTitleBlack">
            Create new{" "}
            <span
              style={{ color: "#28b882" }}
              className="modalTitle modalTitleGreen"
            >
              forum
            </span>
          </span>
        }
        open={open}
        onClose={() => setOpen(false)}
        backgroundColor={"#fffff"}
      >
        <CreateForumForm onClose={() => setOpen(false)} />
      </DialogComponent>
    </>
  );
};

export default connect(
  (state) => ({
    forumList: state.forum.get("forumList"),
  }),
  {
    getForumList: Actions.forum.getForumList,
    joinForum: Actions.forum.joinForum,
    verifyToken: Actions.auth.verifyToken,
  }
)(ForumMainScreen);
