import React, { useRef, useEffect, useState } from "react";
import { Box, Rating, Typography, Grid, Item, Divider, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeadingComponent from "../../../component/atom/Headings/Heading";
import { DashboardStudentCard, ForumCard } from "../../../component/molecule";
import { Actions } from "../../../core/modules/Actions";
import { connect } from "react-redux";
import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import moment from "moment";
import _ from "lodash";
import { IMAGES } from "../../../assets/Images";
import SwipeableViews from "react-swipeable-views";
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    backgroundColor: 'transparent'
  },
  dot: {
    backgroundColor: "#00FFFFFF"
  },
  dotActive: {
    backgroundColor: "#00FFFFFF"
  }
});

const DashboardScreen = ({
  userDashboardData,
  userDashboard,
  getcourseProgress,
  courseprogress,
  verifyToken,
}) => {
  const navigate = useNavigate();
  // const intervalRef = useRef(null);
  const theme = useTheme();
  const [value, setValue] = React.useState(2);
  const [forumData, setForumData] = useState([]);
  const [progress, setProgress] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [maxsteps, setMaxSteps] = useState(0);
  const classes = useStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };


  useEffect(() => {
    userDashboard();
    getcourseProgress();
  }, [navigate]);

  useEffect(() => {
    setForumData(userDashboardData);
    setProgress(courseprogress);
    setMaxSteps(progress.length)
  }, [forumData, courseprogress, progress]);

  useEffect(() => { }, [progress]);

  const onViewForum = (item) => {
    // 👇️ navigate to /contacts
    navigate("/forum-message", {
      state: { forumId: _.get(item, "id", ""), forum: item },
    });
  };

  const navigateToCourseDetails = (params) => {
    navigate("/course-details",{
      state: params,
    });
  };

  return (
    <Grid container p={2} spacing={4} flexDirection={"column"}>
      <Grid  item>
        <Grid container flexDirection={"column"} spacing={1}>
          <Grid item>
            <img
              src={IMAGES.welcomeText}
              alt="Welcome-text"
              style={{
                objectFit: "cover",
                height: "auto",
                width: 400,
                alignSelf: "center",
              }}
            />
          </Grid>
          <Grid item>
            <HeadingComponent
              fontfamily={"Montserrat"}
              size={16}
              color={"#4e657c"}
              text={"Navigating Excellence: Your Learning Adventure at Winspert"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container  justifyContent={'space-between'} rowSpacing={5}>
          <Grid item lg={9} md={8} order={{ md: 1, lg: 1, sm: 2 }}>
            <Grid container flexDirection={'column'} rowSpacing={3.5}>
              <Grid item>
                <HeadingComponent fontweigth={600} size={19} text={"New Threads"} color={"#2D3945"} />
              </Grid>
              <Grid  item>
                <Divider variant="middle" />
                {userDashboardData.map((item, index) => {
                  return (
                    <ForumCard
                      item={item}
                      key={"forum-" + index}
                      heading={item.name}
                      description={item.description}
                      date={moment(
                        new Date(_.get(item, "created_at", new Date()))
                      ).format("Do MMM YYYY")}
                      user={item.creator}
                      onViewForum={() => onViewForum(item)}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} lg={2.5} sm={5.5} order={{ md: 2, lg: 2, sm: 1 }}>
            <Grid container flexDirection={"column"} >
              <Grid item>
                <HeadingComponent text={"In progress"} color={"#2D3945"} size={19} fontweigth={600} />
              </Grid>
              <Grid container sx={{ position: 'relative' }} item>
                <Box sx={{ backgroundColor: '#ffffff'}}>
                  <DashboardStudentCard
                     onButtonClick={(params) => navigateToCourseDetails(params)}
                     list={progress}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};


export default connect(
  (state) => ({
    userDashboardData: state.home.get("userDashboardData"),
    courseprogress: state.home.get("courseprogress"),
  }),
  {
    userDashboard: Actions.home.userDashboard,
    getcourseProgress: Actions.home.getcourseProgress,
    verifyToken: Actions.auth.verifyToken,
  }
)(DashboardScreen);
