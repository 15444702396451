import React, { useState, useEffect, useRef } from "react"
import { Box, Typography, DialogActions, DialogContent } from "@mui/material"
import TextInputComponent from "../../atom/Inputs/TextInput"
import TextButtonComponet from "../../atom/Buttons/TextButton"
import { MINIMUM_PASSWORD_CHARACTERS, USER_ROLE, EMAIL_REGEX, getText, getDeviceId, PASSWORD_REGEX, onGetCurrencySymble } from "../../../core/Constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Actions } from "../../../core/modules/Actions";
import CardValidator from 'card-validator';
import _ from "lodash";
import SignUpFormOne from "./SignUpFormOne";
import SignUpFormTwo from "./SignUpFormTwo";
import SecondStep from "../../../assets/Images/activeSlider.png";
import SecondOne from "../../../assets/Images/Signupgroup.png";

const SignUpForm = ({ onClose = () => { }, paymentsheet = true, item = {}, stepIndex=0, isRegistered=false }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formOneData, setFormOneData] = useState({});
    const [payment, setPayment] = useState(false);
    const formOneRef = useRef();
    const formTwoRef = useRef();
    const [step, setStep] = useState(0);
    const [course, setCourse] = useState({});
    const [isFree, setIsFree] = useState(0);
    const [isLoading, setLoading]=useState(false)

    const loadingAction = useSelector(state => state.common.get('loadingAction'));

    useEffect(() => {
        const { action, loading } = loadingAction;
        const type = action.type;
        if (type == "SIGN_UP" || type =='ENROLL_NEW_COURSE_BY_REGISTERED_USER') {
           setLoading(loading)
        }
    }, [loadingAction]);


    useEffect(()=>{
     setStep(stepIndex);
    },[stepIndex, isRegistered]);


    useEffect(() => {
        setCourse(item);
        setIsFree(item?.is_free);
    }, [item]);

    const onNext = () => {
        setPayment(true);
        formOneRef.current.onPress()
    }

    const onCloseForm = () => {
        setStep(0)
        onClose()
    }

    const onSignUp = () => {
        formTwoRef.current.onPress()
    }


    return (
        <>
            <form >
                <DialogContent>
                    <Box>
                        <Box className='signup-price-container'>
                            <Box>
                                <p className="signup-price-subtext">Selected course</p>
                                <p className="signup-price-text">{_.get(course, 'name', '')}</p>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <p style={{ color: '#4a6375' }} className="signup-price-title">Price</p>
                                <p style={{ color: '#8080f1', fontWeight: 800 }} className="signup-price-title"><span style={{ whiteSpace: 'pre' }}>  </span>{`${onGetCurrencySymble(_.get(course, 'currency', 'GBP'))} ${_.get(course, 'price', '0.00')}`}</p>
                            </Box>
                        </Box>

                        <Box>
                            <Box sx={{ width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 5 }}>
                                <Box
                                    component="img"
                                    sx={{
                                        height: 0.6,
                                        width: 0.6,
                                        alignSelf: 'center',
                                        display: 'flex',
                                    }}
                                    alt="The house from the offer."
                                    src={step == 0 ? SecondOne : SecondStep}
                                />

                            </Box>
                            <>
                                {step == 0 && !isRegistered ?
                                    <SignUpFormOne
                                        isFree={isFree}
                                        course_id={_.get(course, 'id', '')}
                                        onPassValue={async(data) => {
                                            if(isFree){
                                                const device_id = await getDeviceId();
                                                dispatch(Actions.auth.signUp({...data,device_id }));
                                            }else{
                                                setFormOneData(data);
                                                setStep(1)
                                            }
                                        }}
                                        ref={formOneRef} /> :
                                    <SignUpFormTwo
                                        item={formOneData}
                                        course_id={_.get(course, 'id', '')}
                                        onClose={onCloseForm}
                                        isRegistered={isRegistered}
                                        ref={formTwoRef} />
                                }
                            </>

                        </Box>

                    </Box>
                </DialogContent>
                <DialogActions sx={{ padding: 4 }}>
                    {step == 0 && !isRegistered ?
                        <Box sx={{ display: "flex", gap: 5, width: 1, justifyContent: 'center' }}>
                            <TextButtonComponet text={"Cancel"} classStyle="btn btn-secondary" width={120} onButtonClick={() => {
                                onCloseForm()
                            }} />
                            <TextButtonComponet text={isFree?"Sign up": "Next"} onButtonClick={onNext} width={120} />
                        </Box> :
                        <Box sx={{ display: "flex", gap: 5, width: 1, justifyContent: 'center' }}>
                            <TextButtonComponet text={"Cancel"} classStyle="btn btn-secondary" width={120} onButtonClick={() => {
                                onCloseForm()
                            }} />
                            <TextButtonComponet isDisabled={isLoading} text={"Proceed"} width={120} onButtonClick={onSignUp} />
                        </Box>
                    }
                </DialogActions>
            </form>

        </>
    )
}

export default SignUpForm;