import React, { useState, useEffect } from "react";
import { Box, DialogActions, DialogContent } from "@mui/material";
import TextInputComponent from "../../../atom/Inputs/TextInput";
import TextButtonComponet from "../../../atom/Buttons/TextButton";
import TextAreaComponent from "../../../atom/Inputs/TextArea";
import { IconButtonComponent, TextIconButtonComponent } from "../../../atom";
import { useNavigate } from "react-router-dom";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Actions } from "../../../../core/modules/Actions";
import { connect } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import { IMAGE_URL } from "../../../../core/Constant";

const AdminCourseFormView = ({
  onClose,
  courseID,
  getCourseMainDetails,
  mainDetailsState,
}) => {
  const [scroll, setScroll] = useState("paper");
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [lecturer, setLecturer] = useState("");
  const [description, setDescription] = useState("");
  const [course_image, setCourseImage] = useState("");
  const [totalEnrolled, setTotalEnrolled] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    getCourseMainDetails({
      id: courseID,
    });
  }, [courseID]);

  useEffect(() => {
    setName(mainDetailsState.name);
    setDescription(mainDetailsState.description);
    setDuration(mainDetailsState.duration);
    setLecturer(mainDetailsState.lecturer);
    setPrice(
      getSymbolFromCurrency(mainDetailsState.currency) + mainDetailsState.price
    );
    setCourseImage(mainDetailsState.course_image);
    setTotalEnrolled(mainDetailsState.total_enrolled);
  }, [mainDetailsState]);

  return (
    <>
      <form>
        <DialogContent>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
              <TextIconButtonComponent
                icon={faStar}
                btnText={"View Reviews"}
                onclick={() =>
                  navigate("/admin-review", {
                    state: {
                      course_id: courseID,
                    },
                  })
                }
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
              <img
                src={IMAGE_URL + course_image}
                style={{
                  objectFit: "contain",
                  width: "350px",
                  height: "250px",
                }}
                alt="course-image"
              />
            </Box>

            <Box>
              <TextInputComponent
                label={"Name"}
                placeholder="abc"
                value={name}
              />
            </Box>

            <Box>
              <TextInputComponent
                label={"Price"}
                placeholder="$34"
                value={price}
              />
            </Box>
            <Box>
              <TextInputComponent
                label={"Duration (Weeks)"}
                placeholder="3"
                value={duration}
              />
            </Box>

            <Box>
              <TextInputComponent
                label={"Total Enrolled"}
                placeholder="456"
                value={totalEnrolled}
              />
            </Box>

            <Box>
              <TextInputComponent
                label={"Lecturer"}
                placeholder="Tom Hardy"
                value={lecturer}
              />
            </Box>

            <Box m={1}>
            <p style={{ padding: 0, margin: 0, marginBottom:10, color: "#4E657C", fontSize: 19, fontWeight: 500 }}>Description</p>

              <Box  p={1} sx={{ backgroundColor: "#F2F6F8", borderRadius:2 }}>
                <span
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    color: "#4A6375",
                    
                  }}
                >
                  {description}
                </span>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </form>
    </>
  );
};

export default connect(
  (state) => ({
    mainDetailsState: state.course.get("mainDetailsState"),
  }),
  {
    getCourseMainDetails: Actions.course.getCourseMainDetails,
  }
)(AdminCourseFormView);
