import React from "react";
import MUIDataTable from "mui-datatables";

const TableComponent = ({ title, columns, data, filter, download }) => {

  const options = {
    filterType: 'dropdown',
    download: download,
    filter: filter,
    print: false,
    viewColumns: false,
    search: true,
    selectableRowsHideCheckboxes: true
  };

  return (
      <MUIDataTable
        data={data}
        columns={columns}
        options={options}

      />
  )
}

export default TableComponent;