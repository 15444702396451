import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import _ from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, Button, Grid } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { IMAGES } from "../../../assets/Images";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useLocation } from "react-router-dom";
import { adminScreens, studentScreens } from "./Routers";
import { Navigate, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { getSourcePath } from "../../../core/Constant";

const drawerWidth = 240;

const SidebarContainer = ({ window, type }) => {
  const [mobileOpen, setMobileOpen] = React.useState(true);
  const [clickReports, setReportClick] = React.useState(false);
  let location = useLocation();
  const [pathName, setPathName] = React.useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imgPath, setImgPath] = useState("");

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");

  const currentUser = useSelector((state) => state.profile.get("currentUser"));
  useEffect(() => {
    setImgPath(getSourcePath(_.get(currentUser, "profile_image", "")));
    setFName(_.get(currentUser, "first_name", ""));
    setLName(_.get(currentUser, "last_name", ""));
  }, [currentUser]);

  useEffect(() => {
    dispatch(Actions.profile.getUserData());
  }, []);

  const onLogOut = () => {
    navigate("/");
    dispatch(Actions.auth.logOut());
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar sx={{ display: "flex", flexDirection: "column" }}>
        <Box>
          <img
            src={IMAGES.winspertLandscapeLogo}
            style={{ objectFit: "contain", width: 180, height: 100 }}
            alt="sidebar-logo"
            className="sidebar-logo"
          />
        </Box>
        {type !== 0 ? (
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item>
              <Avatar
                alt={`${fName} ${lName}`}
                src={imgPath}
                sx={{ width: 45, height: 45 }}
              />
            </Grid>
            <Grid item>
              <Grid container flexDirection={"column"} rowSpacing={0.2}>
                <Grid item>
                  <span className="sidebar-hellothere-text">Hello There</span>
                </Grid>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: 15,
                    }}
                  >{`${fName} ${lName}`}</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Toolbar>
      <Grid container flexDirection={"column"} alignItems={"center"}>
        <Grid item>
          <List>
            {type === "admin" ? (
              <>
                {adminScreens?.map((page, index) => {
                  let isActive = page?.link == pathName;
                  if (!isActive) {
                    isActive =
                      _.findIndex(
                        _.get(page, "more", []),
                        (item) => item?.link == pathName
                      ) > -1;
                  }
                  return (
                    <DrawerItem
                      key={"admin-drawer-" + index}
                      clickReports={clickReports}
                      setReportClick={setReportClick}
                      index={index}
                      page={page}
                      isActive={isActive}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {studentScreens?.map((page, index) => {
                  const isActive = page?.link == pathName;
                  return (
                    <DrawerItem
                      key={"student-drawer-" + index}
                      clickReports={clickReports}
                      setReportClick={setReportClick}
                      index={index}
                      page={page}
                      isActive={isActive}
                    />
                  );
                })}
              </>
            )}
          </List>
        </Grid>
        <Grid item>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 4 }}>
            <Button
              type="button"
              style={{
                width: 200,
                borderRadius: 10,
                color: "#D06060",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#D1D5DB",
                textTransform: "capitalize",
                fontWeight: 600,
                fontSize: 16,
                fontFamily: "Montserrat, sans serif",
                boxShadow: "0px 26px 36px -7px #7D464321",
              }}
              sx={{
                "&:hover": {
                  borderWidth: "1.5px !important",
                  color: "#4a6375 !important",
                  backgroundColor: "transparent !important",
                  boxShadow: "25px 36px 46px 25px #7D464321 !important",
                },
                "&:active": { backgroundColor: "#fff !important" },
              }}
              onClick={onLogOut}
              startIcon={<PowerSettingsNewIcon fontSize="large" />}
            >
              Logout
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );

  React.useEffect(() => {
    setPathName(location?.pathname);
  }, [location]);

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <Box>
        <Box sx={{ pl: 1.2, pt: 0.5 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth, bgcolor: "#657ee4" },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            className="drawer"
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            className="drawer"
            open
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
};

export default SidebarContainer;

const DrawerItem = ({
  page,
  isActive = false,
  index,
  clickReports = false,
  setReportClick = () => {},
}) => {
  let location = useLocation();
  const [pathName, setPathName] = React.useState("");

  React.useEffect(() => {
    setPathName(location?.pathname);
  }, [location]);

  return (
    <Link to={page.link} key={index}>
      <ListItem disablePadding>
        {page.more ? (
          <ListItemButton
            onClick={() => {
              setReportClick(!clickReports);
            }}
            // onClick={() => { clickReports ? setReportClick(false) : setReportClick(true) }}
          >
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item>{page.icon} </Grid>
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    width: 125,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ListItemText
                    className={
                      isActive
                        ? "active-tab drawer-tab"
                        : "inactive-tab drawer-tab"
                    }
                    primary={page.name}
                  />
                  {clickReports ? (
                    <ExpandLessIcon
                      className={
                        isActive
                          ? "active-tab drawer-tab"
                          : "inactive-tab drawer-tab"
                      }
                      style={{ color: "#4A6375" }}
                      fontSize="medium"
                    />
                  ) : (
                    <ExpandMoreIcon
                      className={
                        isActive
                          ? "active-tab drawer-tab"
                          : "inactive-tab drawer-tab"
                      }
                      style={{ color: "#4A6375" }}
                      fontSize="medium"
                    />
                  )}
                </Box>
              </Grid>
              {clickReports
                ? page?.more?.map((item, index) => {
                    const isSub = item?.link == pathName;

                    return (
                      <Link to={item.link} key={index}>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <Grid
                              container
                              spacing={2}
                              justifyContent={"flex-end"}
                              alignItems={"center"}
                              sx={{ width: 200 }}
                            >
                              <Grid item>
                                {" "}
                                <ListItemText
                                  className={
                                    isSub
                                      ? "active-tab drawer-tab"
                                      : "inactive-tab drawer-tab"
                                  }
                                  primary={item.name}
                                />
                              </Grid>
                            </Grid>
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    );
                  })
                : null}
            </Grid>
          </ListItemButton>
        ) : (
          <ListItemButton>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item>{page.icon}</Grid>
              <Grid item>
                {" "}
                <ListItemText
                  className={
                    isActive
                      ? "active-tab drawer-tab"
                      : "inactive-tab drawer-tab"
                  }
                  primary={page.name}
                />
              </Grid>
            </Grid>
          </ListItemButton>
        )}
      </ListItem>
    </Link>
  );
};
