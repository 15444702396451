import React, { useEffect, useState, useRef } from "react";
import {
  SwitchButtonComponet,
  TextIconButtonComponent,
} from "../../../component/atom";
import { Box, Grid } from "@mui/material";
import HeadingComponent from "../../../component/atom/Headings/Heading";
import DropDownComponent from "../../../component/atom/Inputs/DropDown";
import TableComponent from "../../../component/atom/Table/TableComponent";
import DialogComponent from "../../../component/atom/Dialog/Dialog";
import AddStudentForm from "../../../component/molecule/Forms/AddStudentForm";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import TextButtonComponet from "../../../component/atom/Buttons/TextButton";
import AddReviewForm from "../../../component/molecule/Forms/courseAdmin/AdminAddReviewForm";
import { useNavigate } from "react-router-dom";
import { setTopLevelNavigator } from "../../../core/services/NavigationServicd";

const StudentsScreen = ({
  getAdminUserDetails,
  adminStudents,
  courseList,
  getCourseList,
  studentStatus,
  updateStudentStatus,
}) => {
  const [addStudent, setAddStudent] = useState(false);
  const [OpenReview, setOpenReview] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [courseId, setCourseId] = useState(0);
  const [studentId, setStudentId] = useState(0);
  const hasExecuted = useRef(1);

  useEffect(() => {
    setStudentList(adminStudents);
  }, [adminStudents, courseList, studentList, studentStatus]);

  useEffect(() => {
    getAdminUserDetails({
      course: "",
      type: 2,
    });
    getCourseList();
  }, []);

  useEffect(() => {
    if (!hasExecuted.current === 1) {
      courseList?.unshift({
        id: 0,
        name: "All Courses",
      });
      hasExecuted.current = true;
    }
  }, []);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "course_id",
      label: "",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "payment_details",
      label: "Enrolled Course",
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value) => {
          return value?.map((item) => (
            <>
              <ul>
                <li>{item?.course?.name}</li>
              </ul>
            </>
          ));
        },
      },
    },

    {
      name: "is_active",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <SwitchButtonComponet
              checked={value ? true : false}
              onChange={() => updateAdminStatus(tableMeta, value)}
              inputProps={{ "aria-label": "controlled" }}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Add Review",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box sx={{ minWidth: 150 }}>
              <TextButtonComponet
                text={"Add Review"}
                onButtonClick={() => addReviewBtn(value, tableMeta)}
              />
            </Box>
          );
        },
      },
    },
  ];

  const updateAdminStatus = (tableMeta) => {
    const updatedStudents = studentList?.map((item) => {
      if (item?.id == tableMeta?.rowData[0]) {
        const currentStatus = item?.is_active;
        const updatedStatus = currentStatus == 1 ? 0 : 1;
        updateStudentStatus({
          user_id: tableMeta?.rowData[0],
          status: updatedStatus,
        });
        return { ...item, updatedStatus };
      }
      return item;
    });

    setStudentList(updatedStudents);
  };

  const filterStudentsDetails = (courseId) => {
    setCourseId(courseId);
    console.log(courseId)
    getAdminUserDetails({
      course: courseId == 0 ? "" : courseId,
      type: 2,
    });
  };

  const addReviewBtn = (value, tableMeta) => {
    setStudentId(value);
    setCourseId(tableMeta?.rowData[1]);
    setOpenReview(true);
  };

  return (
    <>
      <Box className="main-screen-container">
        <Grid container direction="row" justifyContent="space-between">
          <HeadingComponent
            text={"Students"}
            fontweigth={600}
            size={40}
            fontfamily={"Montserrat"}
          />
          <Grid className="student-search-btn-section">
            <TextIconButtonComponent
              buttonStyleClass="btn btn-primary"
              icon={faUserPlus}
              btnText={"Add Student"}
              onclick={() => setAddStudent(true)}
            />
            <Box className="student-search-btn-inner-section">
              <DropDownComponent
                list={courseList}
                initialValue={"All Courses"}
                selectedValue={courseId}
                onchange={(e) => filterStudentsDetails(e.target.value)}
                placeholder={"All Courses"}
              />
            </Box>
          </Grid>
        </Grid>

        <Box className="common-admin-content-wrap">
          <TableComponent columns={columns} data={studentList} filter={false} />
        </Box>
      </Box>

      <DialogComponent
        title={"Add Student"}
        children={
          <AddStudentForm
            dropdownList={courseList}
            onclickcancel={() => setAddStudent(false)}
          />
        }
        onClose={() => setAddStudent(false)}
        open={addStudent}
      />
      <DialogComponent
        title={"Add Review"}
        children={
          <AddReviewForm
            courseId={courseId}
            courses={courseList}
            userId={studentId}
            dropdownList={courseList}
            onclickcancel={() => setOpenReview(false)}
          />
        }
        onClose={() => setOpenReview(false)}
        open={OpenReview}
      />
    </>
  );
};

export default connect(
  (state) => ({
    adminStudents: state.students.get("adminStudents"),
    courseList: state.students.get("commonCourseList"),
    studentStatus: state.students.get("studentStatusUpdate"),
  }),
  {
    getAdminUserDetails: Actions.students.getAdminUserDetails,
    getCourseList: Actions.students.getCourseList,
    updateStudentStatus: Actions.students.updateStudentStatus,
  }
)(StudentsScreen);
