import React from "react";
import { Box, Card, CardContent, CardMedia, Grid } from "@mui/material";
import TextButtonComponet from "../../atom/Buttons/TextButton";
import { StarRatingoComponent } from "../../atom";

const CourseCard = ({
  image,
  title,
  description,
  btnText,
  onCourseDetails = () => { },
  price,
  currancy,
  reviewCount,
  reviewAvg,
  weeks,
  selectValue = 0,
  isRegisterd,
  isFree,
}) => {
  return (
    <Card sx={{ maxWidth: 315, borderRadius: 8 }}>
      <Box
        sx={{
          position: "relative",
          backgroundImage:
            "linear-gradient(to top, #2d3945, #292b34, #211e24, #161214, #000000)",
        }}
      >
        <CardMedia
          component="img"
          height={180}
          image={image}
          alt="Card-image"
          style={{ opacity: 0.9 }}
        />
        <span
          style={{
            position: 'absolute',
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: 24,
            bottom: 10,
            left: "5%",
            textShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 10px',
            borderRadius: 10,
          }}
        >
          {title}
        </span>
      </Box>
      <CardContent>
        <Grid container flexDirection={"column"} rowSpacing={2}>
          <Grid item>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item>
                <StarRatingoComponent readOnly selectValue={reviewAvg} />
              </Grid>
              <Grid item>
                <span className="course-catalog-card-text">
                  {reviewCount} Reviews
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <p className="course-catelog-card-gray-bg">{weeks} Weeks</p>
              </Grid>
              <Grid item>
                <p className="course-catelog-card-gray-bg">
                  {currancy} {price}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <span
              className="course-catalog-card-description"
              title={description}
            >
              {description}
            </span>
          </Grid>
          <Grid item>
            {isRegisterd == 1 ? (
              <TextButtonComponet
                text={"View"}
                onButtonClick={onCourseDetails}
              />
            ) : (
              <TextButtonComponet
                text={"Preview"}
                classStyle="btn course-catalog-preview"
                onButtonClick={onCourseDetails}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CourseCard;
