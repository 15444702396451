import React, { useState, useEffect } from "react";
import { Avatar, Box } from "@mui/material";
import HeadingComponent from "../../../../component/atom/Headings/Heading";
import TableComponent from "../../../../component/atom/Table/TableComponent";
import { DialogComponent, IconButtonComponent,SwitchButtonComponet } from "../../../../component/atom";
import AdminForumEditForm from "../../../../component/molecule/Forms/forumAdmin/AdminForumEditForm";
import { useNavigate } from "react-router-dom";
import { PopUpMessageComponent } from "../../../../component/molecule";
import { Actions } from "../../../../core/modules/Actions";
import { connect } from 'react-redux'
import { IMAGE_URL } from "../../../../core/Constant";
import _ from "lodash"


const AdminMainForumScreen = ({ getForumList, forumList, updateStatus, deleteAdminForum, updateForumStatus, verifyToken }) => {
    const [edit, setEdit] = useState(false);
    const [forumDetails, setForumDetails] = useState({});
    const [deleteForum, setDeleteForum] = useState(false);
    const [userId, setUserId] = useState(0);
    const [forums, setForums] = useState([]);

    const navigate = useNavigate(false);

    const onNavigate = (path, obj = {}) => {
        if(path){
            navigate(path, obj);
         }
    }

    useEffect(() => {
        getForumList();
    }, []);

    useEffect(() => {
        setForums(forumList);
    }, [forumList])



    const columns = [
        {
            name: "id",
            label: "Id",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },

        {
            name: "image",
            label: "Image",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <Avatar variant="rounded" src={IMAGE_URL + value} />
                    );
                }
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: false,
            }
        },

        {
            name: "forum_participants_count",
            label: "No of Students",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "creator",
            label: "Created By",
            options: {
                filter: true,
                sort: false,
            }
        },


        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SwitchButtonComponet
                            checked={value ? true : false}
                            onChange={() => statusUpdate(tableMeta, value)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    );
                }
            }
        },

        {
            name: "view",
            label: "View",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButtonComponent btnType={"viewIconbtn"} disabled={tableMeta.rowData[5] == 0 ? true : false} onclick={() => onNavigateUser(tableMeta, value)} />
                    );
                },
            }
        },
        {
            name: "upload",
            label: "Edit",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButtonComponent btnType={"editbtn"} onclick={() => updateBtn(tableMeta)} />
                    );
                },
            }
        },
        {
            name: "delete",
            label: "Delete",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButtonComponent btnType={"deleteIconbtn"} onclick={() => deleteBtnpress(tableMeta)} />
                    );
                },
            }
        },
    ];

    const onNavigateUser = (tableMeta, value) => {
        for (const item of forums) {
            if (item?.id == tableMeta?.rowData[0]) {
                // navigate("/admin-view-forum",{ state: { forumId: _.get(item, 'id', ''), forum: item } })
                onNavigate("/admin-view-forum",{ state: { forumId: _.get(item, 'id', ''), forum: item } })
            }

        }
    }

    const statusUpdate = (tableMeta, value) => {
        const updatedForms = forums?.map((item) => {
            if (item?.id == tableMeta?.rowData[0]) {
                const currentStatus = item?.status
                const status = currentStatus == 1 ? 0 : 1;
                updateForumStatus({ forum_id: tableMeta?.rowData[0], status });
                return { ...item, status }
            }
            return item;
        });
        setForums(updatedForms);
    }

    const updateBtn = (tableMeta) => {
        forums?.map(forum => {
            if (forum?.id == tableMeta.rowData[0]) {
                setForumDetails(forum);
            }
        })
        setEdit(true)
    }

    const deleteBtnpress = (tableMeta) => {
        forums?.map(forum => {
            if (forum?.id == tableMeta.rowData[0]) {
                setUserId(forum?.id);
            }
        });

        setDeleteForum(true);
    }

    const deleteForumById = () => {
        deleteAdminForum(userId);
        setDeleteForum(false);
    }


    return (<>
        <Box className="main-screen-container">
            <Box>
                <HeadingComponent text={"Forum"} fontweigth={600} size={40} fontfamily={"Montserrat"} />
            </Box>

            <Box className="common-admin-content-wrap">
                <TableComponent columns={columns} data={forums} />
            </Box>
        </Box>
        <PopUpMessageComponent open={deleteForum} type={"other"} title={"Delete!"} message={"Are you sure you want delete forum?"} btntext={"Yes, delete"} altbtntext={"No"} altonclick={() => setDeleteForum(false)} onclick={deleteForumById} onclose={() => setDeleteForum(false)} />
        <DialogComponent isShowCloseButton={false} title={"Edit Forum"} open={edit} onClose={() => setEdit(false)} >
            <AdminForumEditForm forumDetails={forumDetails} onClose={() => setEdit(false)} />
        </DialogComponent>


    </>);
}

export default connect(
    state => ({
        forumList: state.forum.get("forumList"),
        updateStatus: state.forum.get("editForumStatus")
    }),
    {
        getForumList: Actions.forum.getForumList,
        updateForumStatus: Actions.forum.updateAdminForumStatus,
        deleteAdminForum: Actions.forum.deleteAdminForum,
        verifyToken: Actions.auth.verifyToken
    },
)(AdminMainForumScreen);