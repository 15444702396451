import React from "react";
import { Box } from "@mui/material";

const DashboardCard = ({ value, text, icon }) => {
    return (
        <Box className="dashboard-card-container">
            <Box className="dashboard-card-wrap">
                <Box className="dashboard-card-text">
                    <Box sx={{ textAlign: "center" }}>
                        <h2 className="dashboard-users">{value < 10 ? "0" + value : value}</h2>
                        <h5 className="dashboard-users-text">{text}</h5>
                    </Box>
                    <Box>
                        <img src={icon} alt="userIcon" className="dashboard-card-logo" />
                    </Box>

                </Box>
            </Box>
        </Box>
    );
}

export default DashboardCard;