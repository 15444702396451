import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, Rating, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SidebarContainer } from "../../../component/organism";
import CourseCard from "../../../component/molecule/Cards/CourseCard";
import HeadingComponent from "../../../component/atom/Headings/Heading";
import { Actions } from "../../../core/modules/Actions";
import { connect } from "react-redux";
import _ from "lodash";
import {
  IMAGE_URL,
  onGetCountrySymble,
  onGetCurrencySymble,
} from "../../../core/Constant";
import { setTopLevelNavigator } from "../../../core/services/NavigationServicd";

const CourseScreen = ({ coursecatalog, getCousreCatalog, verifyToken, getInitialCourseDetails }) => {
  const navigate = useNavigate();
  const [newcoursecatalog, setNewCourseCatalog] = useState([]);
  const navigateToCourseDetails = (courseId, isRegistered, course) => {
    if (!isRegistered) {
      getInitialCourseDetails()
    }
    navigate("/course-details", {
      state: {
        course_id: courseId,
        is_registered: isRegistered,
        course
      },
    });
  };

  useEffect(() => {
    getCousreCatalog();
    verifyToken();
  }, []);

  useEffect(() => {
    setNewCourseCatalog(coursecatalog);
  }, [newcoursecatalog, coursecatalog]);

  return (
    <>
      <Box className="main-screen-container">
        <Box mt={2}>
          <HeadingComponent
            text={"Course Catalog"}
            fontweigth={600}
            size={26}
            fontfamily={"Montserrat"}
          />
        </Box>

        <Box sx={{ ml: 8, mt: 6,}}>
          <Grid
            container
            spacing={2}
            columnSpacing={{ sm: 12, md: 5, lg: 8, xl: 10 }}
          >
            {newcoursecatalog?.map((item, index) => {
              return (
                <>
                  <Grid item key={item?.id + `${index}`}>
                    <CourseCard
                      image={IMAGE_URL + item?.course_image}
                      selectValue={2}
                      price={item?.price}
                      reviewCount={item?.totalRatingCount}
                      reviewAvg={item?.averageRating}
                      currancy={`${onGetCurrencySymble(
                        _.get(newcoursecatalog?.[0], "currency"),
                        "USD"
                      )}`}
                      weeks={item?.duration}
                      onCourseDetails={() =>
                        navigateToCourseDetails(item?.id, item?.is_registered, item)
                      }
                      title={item?.name}
                      btnText={"View"}
                      description={item?.description}
                      isRegisterd={item?.is_registered}
                      isFree={_.get(item, 'is_free', 0)}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default connect(
  (state) => ({
    coursecatalog: state.course.get("coursecatalog"),
  }),
  {
    getCousreCatalog: Actions.course.getCousreCatalog,
    verifyToken: Actions.auth.verifyToken,
    getInitialCourseDetails: Actions.guest.getInitialCourseDetails
  }
)(CourseScreen);
