import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import StarRatingoComponent from "../../atom/Buttons/StarRating";
import TextButtonComponet from "../../atom/Buttons/TextButton";
import AdminVideoCard from "./admin/AdminVideoCard";
import { Card } from "@mui/material";
import { CardMedia } from "@mui/material";
import { IMAGE_URL } from "../../../core/Constant";

const SampleVideoCard = ({
  week,
  data,
  thumbnail,
  btnText,
  description,
  title,
  onViewVideo = () => {},
}) => {
  useEffect(() => {});
  // return (<Box sx={{
  //   display:'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   width: '250px',
  //   height: "auto",
  //   flexGrow: 0,
  //   justifyContent: 'center',
  //   // padding: 0 0 15px;
  //   // border-radius: 33px;
  //   // box-shadow: 0 5.5px 27.5px -2.8px rgba(95, 95, 95, 0.25);
  //   backgroundColor: '#fff'}}
  // >
  return (
    // <Box>
    //   <Card style={{
    //     position: 'relative',
    //     width: '250px',
    //     height: "auto",
    //     flexGrow: 0,
    //     marginTop: "10px",
    //     marginBottom:'10px',
    //     marginRight:'10px',
    //     alignItems:'center',
    //     boxShadow: '0 5.5px 27.5px -2.8px rgba(95, 95, 95, 0.25)',
    //     justifyContent: 'center', borderRadius: 15
    //   }}>
    //     <CardMedia image={IMAGE_URL + thumbnail} style={{
    //       // height: 0,
    //       paddingTop: '56.25%', borderRadius: 15, alignItems: 'center', boxShadow: '0 21px 32px 0 rgba(86, 86, 86, 0.35)'
    //     }} />
    //     <span style={{
    //       position: 'absolute',
    //       top: '32%',
    //       fontWeight: 600, fontFamily: 'Montserrat',
    //       fontSize: 20,
    //       alignSelf:'center',
    //       textAlign:'start',
    //       textShadow:5,
    //       width:'100%',
    //       color: '#fff', textAlign: 'center', alignSelf: 'center'
    //     }}>
    //     {description}
    //     </span>
    //     <Box m={1.5}>
    //       <Typography textAlign={'center'}   marginTop={5} fontFamily={'Montserrat'} color={'#8c8c8c'} fontWeight={500} fontSize={20} variant="p" component="div" >
    //       {title}
    //       </Typography>
    //     </Box>
    //     <Box marginTop={2} marginBottom={2} padding={1}>
    //       <TextButtonComponet onButtonClick={onViewVideo} text={btnText} />
    //     </Box>
    //   </Card>
    // </Box>

    <Card sx={{ maxWidth: 300, minWidth: 300, borderRadius: 4 }}>
      <Box
        sx={{
          position: "relative",
          backgroundImage:
            "linear-gradient(to top, #2d3945, #292b34, #211e24, #161214, #000000)",
        }}
      >
        <CardMedia
          component="img"
          height="220"
          image={IMAGE_URL + thumbnail}
          alt="Card-image"
          style={{ opacity: 0.9 }}
        />
        <span
          style={{
            position: "absolute",
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: 24,
            bottom: 10,
            left: "5%",
            textShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 10px',
          }}
        >
          {description}
        </span>
      </Box>
      <CardContent>
        <Grid container>
          <Grid item height={50}>
            <span
              title={title}
              style={{
                fontSize: 15,
                fontWeight: 500,
                fontFamily: "Montserrat",
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: 3,
                maxHeight: "4.3rem",
                color: "#8C8C8C",
              }}
            >
              {title}
            </span>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <TextButtonComponet onButtonClick={onViewVideo} text={btnText} />
      </CardActions>
    </Card>
  );

  // </Box>);
};

export default SampleVideoCard;

const styles = {
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  card: {
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: "20px",
    left: "20px",
    color: "black",
    backgroundColor: "white",
  },
};
