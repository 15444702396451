import { Box, Typography, DialogActions, DialogContent, Alert, Stack } from "@mui/material"
import TextInputComponent from "../../atom/Inputs/TextInput"
import TextButtonComponet from "../../atom/Buttons/TextButton"
import { useState, useImperativeHandle, forwardRef, useEffect } from "react"
import { MINIMUM_PASSWORD_CHARACTERS, USER_ROLE, EMAIL_REGEX, getText, getDeviceId, PASSWORD_REGEX } from "../../../core/Constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Actions } from "../../../core/modules/Actions";
import CardValidator from 'card-validator';
import _ from "lodash";

const SignUpFormTwo = forwardRef(({ item = {}, course_id, onClose = () => { }, isRegistered=0 }, ref) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cardNumber, setCardNumber] = useState('');
    const [isValidCardNumber, setIsValidCardNumber] = useState(false);
    const [cardNumberError, setCardNumberError] = useState(false);

    const [cvv, setCvv] = useState('');
    const [isValidCvv, setIsValidCvv] = useState(false);
    const [cvvError, setCvvError] = useState(false);


    const [times, setTime] = useState(0);
    const [show, setShow] = useState(false);

    const [date, setDate] = useState('');
    const [isValidDate, setIsValidDate] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [code, setcode] = useState({ name: 'CVV', size: 3 });
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [cardType, setCardType] = useState('');

    const signUpresponse = useSelector(state => state.auth.get("signUpresponse"));
    const [statusIndex, setStatusIndex] = useState(0);

    const [errMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (!signUpresponse?.status && signUpresponse?.status_code == 0) {
            setCardNumberError(true);
            setErrorMessage(signUpresponse?.message)
        }
        else if (signUpresponse?.status) {
            setErrorMessage('')
            setMessage(signUpresponse?.message)
            _.delay(() => {
                onClose();
            }, 1000)
        }
        setStatusIndex(signUpresponse?.status_code)
    }, [signUpresponse])


    const onChangeCvv = (e) => {
        const text = getText(e)
        const validation = CardValidator.cvv(text.toString().trim(), code.size);
        let isValid = validation['isValid'];
        setCvv(text);
        setIsValidCvv(isValid);
        setCvvError(false);
    };

    const onChangeDate = (e) => {
        const text = getText(e)
        const data = onExpDate(text);
        const validation = CardValidator.expirationDate(data.toString().trim());
        let { isValid, month, year } = validation;
        if (isValid) {
            if (year.length === 2) {
                year = 20 + year;
            }
            setMonth(month);
            setYear(year);
        }
        setIsValidDate(isValid);
        setDate(data);
        setDateError(false);
    }

    const onExpDate = (value) => {
        let date = value;
        let time = times;
        const stringArray = value.trim().split('');
        if (stringArray.length === 1 && parseInt(stringArray[0]) > 1) {
            date = '0' + stringArray[0] + '/';
        }
        if (stringArray.length === 2 && time === 0) {
            if (
                time === 0 &&
                parseInt(stringArray[0]) >= 1 &&
                parseInt(stringArray[1] > 2)
            ) {
                date = stringArray[0] + '2/';
            } else {
                date = date + '/';
            }
            time = stringArray.length;
            setTime(time);
            return date;
        }
        if (stringArray.length === 2 && time === 2) {
            date = stringArray[0] + stringArray[1];
            time = 0;
        }
        setTime(time);
        return date;
    };


    const onChangeCardNumber = (e) => {
        let text = getText(e);
        let number = text;
        text = text.trim();
        if (text && text.length > 0) {
            text = text.replace(/[&\/\\#,_= +()$~%.'":*?<>{}-]/g, '');
        }

        const validation = CardValidator.number(text.trim());
        let isValid = validation['isValid'] //|| validation['isPotentiallyValid'];
        const code = _.get(validation, 'card.code', null);
        setCardType(_.get(validation, 'card.type', ''))
        if (!_.isNull(code)) {
            setcode(code);
            if (cvv.length > 0) {
                const validationCVV = CardValidator.cvv(
                    cvv.toString().trim(),
                    code.size,
                );
                setIsValidCvv(validationCVV['isValid'])
            }
        }
        if (text.length > 3 && text.length % 4 == 0) {
            number = text.match(/\d{4}/g);
            number = number?.join(" ");
        }
        setCardNumber(number);
        setIsValidCardNumber(isValid);
        setCardNumberError(false);
        setErrorMessage("Please add valid card number")
    };

    useImperativeHandle(ref, () => ({
        onPress() {
            onSignUp();
        }
    }))


    const onSignUp = async () => {
        const device_id = await getDeviceId();
        if (isValidCardNumber && isValidCvv && isValidDate) {
            const data = {
                device_id,
                course_id,
                cvc: cvv,
                card_no: cardNumber.split(" ").join(""),
                exp_month: month,
                exp_year: year,
                is_free:0,
                ...item
            };
            if(isRegistered){
                dispatch(Actions.course.registerNewCourseByUser(data))
            } else{
                dispatch(Actions.auth.signUp(data));
            }
        } else {
            if (!isValidCardNumber) {
                setCardNumberError(true)
            }
            if (!isValidCvv) {
                setCvvError(true)
            }
            if (!isValidDate) {
                setDateError(true)
            }
        }
    }

    return (
        <>
            {statusIndex == 1 ? <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="success">{message}</Alert>
            </Stack> : null}
            <p className="signup-title">Enter your card details to purchase</p>
            <TextInputComponent
                label={"Card Number"}
                placeholder="Eg: 1234 XXXX XXXX XXXX"
                value={cardNumber}
                onchange={onChangeCardNumber}
                error={errMessage}
                isError={cardNumberError}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box sx={{ flexGrow: 1, marginRight: 2 }}>
                    <TextInputComponent
                        label={"Exp Date"}
                        placeholder="MM/YY"
                        value={date}
                        onchange={onChangeDate}
                        error="Please add valid date"
                        isError={dateError}
                    />

                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <TextInputComponent
                        label={"CVV"}
                        placeholder="XXX"
                        value={cvv}
                        onchange={onChangeCvv}
                        error="Please add valid cvv"
                        isError={cvvError}
                    />
                </Box>
            </Box>
        </>
    )
})

export default SignUpFormTwo;