import { Avatar, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AnswerView, RadioButtonComponent } from "../../../atom";
import HeadingComponent from "../../../atom/Headings/Heading";
import _ from "lodash";
import { getSourcePath } from "../../../../core/Constant";

const AdminQuestionView = ({ item }) => {
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    const answers = _.get(item, "answers", []);
    const correct = _.filter(answers, (item) => item.is_correct == 1);
    setAnswers(answers);
    setCorrectAnswers(correct);
  }, [item]);

  return (
    <Box className="admin-question" p={3}>
      {!item?.image == null || !item?.image == "" ? (
        <div
          style={{
            with: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            alt="Image"
            src={getSourcePath(_.get(item, "image", ""))}
            sx={{ width: 170, height: 170, borderRadius: 5 }}
          />
        </div>
      ) : null}

      <Box>
        <p
          style={{
            fontFamily: "Montserrat, sans serif",
            fontWeight: 700,
            fontSize: 25,
            color: "#4A6375",
          }}
        >
          {`${_.get(item, "question", "")}`} ?
        </p>
      </Box>

      {answers.map((i, id) => {
        return <AnswerView item={i} key={`admin-answ-${id}`} />;
      })}
      <Box mt={3} mb={3}>
        <HeadingComponent
          text={"Correct Answer"}
          size={25}
          fontweigth={700}
          fontfamily={"Montserrat, sans serif"}
        />
        {correctAnswers.map((i, id) => {
          return (
            <AnswerView isShowRadio={false} item={i} key={`admin-answ-${id}`} />
          );
        })}
      </Box>

      <Box mt={3} mb={3}>
        <HeadingComponent
          text={"Reason"}
          size={25}
          fontweigth={700}
          fontfamily={"Montserrat, sans serif"}
        />
        <Box pl={1}>
          <p
            style={{
              fontFamily: "Montserrat, sans serif",
              fontWeight: 700,
              fontSize: 20,
              color: "#4A6375",
              padding: 0,
              margin: 0,
            }}
          >{`${_.get(item, "reason", "")}`}</p>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminQuestionView;
