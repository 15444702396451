import { Box } from "@mui/material";

const AssementButton = ({ text, width = 'auto', color, onButtonClick, backgroundColor = 'white', btncolor = '' }) => {
    return (<>
        <button onClick={onButtonClick} style={{ width: width, color: color, backgroundColor: backgroundColor, fontFamily: "Montserrat, sans serif", fontWeight: 600, display: 'flex', flexDirection: 'row', border: 'none' }}>
            {text}
            <Box style={{ marginLeft: '15px', width: '20px', height: '20px', borderRadius: 30, backgroundColor: btncolor }}>

            </Box>
        </button>
    </>)
}
export default AssementButton;