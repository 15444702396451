import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { ImageListCard } from "../ForumMessage/ImageCardList";
import { useEffect } from "react";
import { USER_ROLE, getSourcePath } from "../../../core/Constant";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { PopUpMessageComponent } from "../../molecule";
import { useLocation, useNavigate } from "react-router";

const MessageComponent = ({ type, item }) => {
  const [images, setImages] = useState([]);
  const [userRole, setUserRole] = useState(0);
  const [currentUserId, setCurrentUserId] = useState(0);
  const dispatch = useDispatch();
  const [confirmDeleteMessage, setConfirmDeleteMessage] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setImages(_.get(item, "attachment", []));
    setUserRole(parseInt(localStorage.getItem("userType")));
    setCurrentUserId(parseInt(localStorage.getItem("userId")));
  }, [item, currentUserId, userRole]);

  const deleteReply = () => {
   
    const userId = location?.state?.user_id;
      dispatch(Actions.message.deleteMessage(item?.id, userRole==USER_ROLE.student ? currentUserId : userId ));
    setConfirmDeleteMessage(false);
  };
  return (
    <>
      {type === 1 ? (
        <Box>
          <Box
            sx={{ backgroundColor: "#2D3945", mt: 0.5, p: 2, borderRadius: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    margin: 0,
                    padding: 0,
                    fontFamily: "Montserrat",
                    color: "#28B882",
                  }}
                >{`${_.get(item, "creator", "")}`}</span>

                {images?.length > 0 ? (
                  <>
                    <Box>
                      {images.map((item, index) => (
                        <Box key={index} sx={{ m: 1 }}>
                          <img
                            src={`${getSourcePath(item)}`}
                            srcSet={`${getSourcePath(item)}`}
                            alt={`index`}
                            loading="lazy"
                            style={{
                              objectFit: "contain",
                              maxHeight: 200,
                              borderRadius: 15,
                              height: "auto",
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </>
                ) : null}

                <p
                  style={{
                    fontWeight: 500,
                    fontSize: 15,
                    fontFamily: "Montserrat",
                    color: "#FFFFFF",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {_.get(item, "message", "")}
                </p>
                <Box
                  sx={{
                    display: "flex",
                    gap: 0,
                    mr: 0,
                    justifyContent: "flex-end",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      margin: 0,
                      padding: 0,
                      fontFamily: "Montserrat",
                      color: "#9EAFBE",
                    }}
                  >
                    {moment(
                      new Date(_.get(item, "created_at", new Date()))
                    ).format("DD/MM/YYYY hh:mm:A")}
                  </span>
                </Box>
              </div>

              {/* {!userRole ? (
                <IconButton
                  style={{ background: "#fff", marginLeft: "20px" }}
                  aria-label="delete"
                  onClick={() => {
                    deleteReply(_.get(item, "id", ""));
                  }}
                >
                  <DeleteIcon sx={{ color: "#ff3323" }} fontSize="small" />
                </IconButton>
              ) : null} */}
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              backgroundColor: "#fff",
              mt: 0.5,
              p: 2,
              borderRadius: 2,
              borderStyle: "solid",
              borderWidth: 0.5,
              borderColor: "#9EAFBE",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    margin: 0,
                    padding: 0,
                    fontFamily: "Montserrat",
                    color: "#28B882",
                  }}
                >{`${_.get(item, "creator", "")}`}</span>

                {images?.length > 0 ? (
                  <>
                    <Box>
                      {images.map((item, index) => (
                        <Box key={index} sx={{ m: 1 }}>
                          <img
                            src={`${getSourcePath(item)}`}
                            srcSet={`${getSourcePath(item)}`}
                            alt={`index`}
                            loading="lazy"
                            style={{
                              objectFit: "contain",
                              maxHeight: 200,
                              borderRadius: 15,
                              height: "auto",
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </>
                ) : null}

                <p
                  style={{
                    fontWeight: 500,
                    fontSize: 15,
                    fontFamily: "Montserrat",
                    color: "#4A6375",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {_.get(item, "message", "")}
                </p>
                <Box
                  sx={{
                    display: "flex",
                    gap: 0,
                    mr: 0,
                    justifyContent: "flex-end",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      margin: 0,
                      padding: 0,
                      fontFamily: "Montserrat",
                      color: "#9EAFBE",
                    }}
                  >
                    {moment(
                      new Date(_.get(item, "created_at", new Date()))
                    ).format("DD/MM/YYYY hh:mm:A")}
                  </span>
                </Box>
              </div>

              <IconButton
                style={{ background: "#fff", marginLeft: "20px" }}
                aria-label="delete"
                onClick={() => {
                  setConfirmDeleteMessage(true);
                }}
              >
                <DeleteIcon sx={{ color: "#ff3323" }} fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </>
      )}
      <PopUpMessageComponent
        open={confirmDeleteMessage}
        type={"other"}
        title={"Delete!"}
        message={"Are you sure you want to delete this message?"}
        btntext={"Yes, delete"}
        onclick={() => deleteReply(_.get(item, "id", ""))}
        altbtntext={"No"}
        altonclick={() => setConfirmDeleteMessage(false)}
        onclose={() => setConfirmDeleteMessage(false)}
      />
    </>
  );
};
export default MessageComponent;
