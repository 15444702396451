import React, { useEffect, useState } from "react";
import { Box, DialogActions, DialogContent } from "@mui/material";
import {
  PhoneNumberComponent,
  StarRatingoComponent,
  TextInputComponent,
} from "../../../atom";
import DropDownComponent from "../../../atom/Inputs/DropDown";
import TextButtonComponet from "../../../atom/Buttons/TextButton";
import { useDispatch } from "react-redux";
import { Actions } from "../../../../core/modules/Actions";
import { EMAIL_REGEX } from "../../../../core/Constant";
import TextAreaComponent from "../../../atom/Inputs/TextArea";

const AddReviewForm = ({ onclickcancel, courses, userId, courseId }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    courseId: "",
    rating: "",
    feedback: "",
  });

  const [errors, setErrors] = useState({
    courseId: "",
    rating: "",
    feedback: "",
  });

  useEffect(() => {}, [errors]);

  const emptyFormfields = () => {
    setFormData({
      feedback: "",
    });
  };

  const validateForm = () => {
    const newErrors = {
      feedback: "",
    };
    if (!formData?.feedback) {
      newErrors.feedback = "Feedback is required";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.values(newErrors).every((error) => !error);
  };

  const handleChange = async (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      dispatch(
        Actions.review.addStudentReviewByAdmin({
          user_id: userId,
          course_id: courseId,
          rating: formData.rating,
          feedback: formData.feedback,
        })
      );

      onclickcancel();
      emptyFormfields();
    }
  };
  return (
    <Box p={1}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box>
            <Box mt={2} mb={2}>
              <Box style={{ padding: 10 }}>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    color: "#4E657C",
                    fontSize: 19,
                    fontWeight: 500,
                  }}
                >
                  Rating
                </p>

                <StarRatingoComponent
                  name="simple-controlled"
                  onChange={(e) => handleChange("rating", e.target.value)}
                  value={formData.rating}
                  precision={1}
                />
              </Box>
            </Box>

            <Box mt={2} mb={2}>
              <TextAreaComponent
                textlabel={"Feedback"}
                name="feedback"
                value={formData.feedback}
                onchange={(e) => handleChange("feedback", e.target.value)}
                placeholder="feedback"
                readOnly={false}
                isError={errors.feedback !== "" ? true : false}
                error={errors?.feedback}
              />
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Box sx={{ display: "flex", width: "50%", mr: 2.2, mb: 2, ml: 2.2 }}>
            <Box sx={{ display: "flex", gap: 0.5, flexGrow: 1 }}>
              <Box sx={{ flexGrow: 1 }}>
                <TextButtonComponet
                  text="Add"
                  classStyle="btn btn-primary"
                  onButtonClick={handleSubmit}
                />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <TextButtonComponet
                  text="Cancel"
                  classStyle="btn btn-secondary"
                  onButtonClick={() => {
                    onclickcancel();
                    emptyFormfields();
                    setErrors({
                      courseId: "",
                      rating: "",
                      feedback: "",
                    });
                  }}
                />
              </Box>
            </Box>
          </Box>
        </DialogActions>
      </form>
    </Box>
  );
};

export default AddReviewForm;
