import React, { useRef, useEffect, useState } from "react";
import {
  AdminVideoCard,
  PopUpMessageComponent,
  VideoForm,
} from "../../../component/molecule";
import { Box, Grid } from "@mui/material";
import HeadingComponent from "../../../component/atom/Headings/Heading";
import {
  DialogComponent,
  IconButtonComponent,
  TextIconButtonComponent,
} from "../../../component/atom";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTopLevelNavigator } from "../../../core/services/NavigationServicd";

const VideoScreen = ({
  uploadedVideoList,
  popupClose,
  fetchVideo,
  loadingAction,
  verifyToken,
}) => {
  const [videoUpload, setUploadVideo] = useState(false);
  const [uploadedVideos, setUploadedVideos] = useState([]);

  useEffect(() => {
    fetchVideo();
  }, []);

  useEffect(() => {
    setUploadedVideos(uploadedVideoList);
  }, [uploadedVideoList]);

  useEffect(() => {
    const { action, loading } = loadingAction;
    const type = action.type;
    if (type == "UPLOAD_VIDEO" && !loading) {
      setUploadVideo(loading);
    }
  }, [loadingAction]);

  const renderUploadedVideos = uploadedVideos.map((item, index) => (
    <AdminVideoCard
      key={item?.id}
      id={item?.id}
      description={item?.title}
      thumbnail={item?.thumbnail}
      btnText={"Copy Link"}
      videoUrl={item?.link}
    />
  ));

  return (
    <>
      <Box className="main-screen-container">
        <Grid container direction="row" justifyContent="space-between">
          <HeadingComponent
            text={"Video"}
            fontweigth={600}
            size={40}
            fontfamily={"Montserrat"}
          />
          <TextIconButtonComponent
            btnText={"Upload Video"}
            icon={faVideo}
            onclick={() => setUploadVideo(true)}
          />
        </Grid>

        <Grid
          container
          mt={1}
          justifyContent={"space-between"}
          columns={{ xs: 1, sm: 2, md: 4 }}
          rowSpacing={4}
        >
          {renderUploadedVideos}
        </Grid>
      </Box>
      <DialogComponent
        title={"Upload Video"}
        open={videoUpload}
        onClose={() => setUploadVideo(false)}
      >
        <VideoForm
          isClsose={videoUpload}
          onClose={() => setUploadVideo(false)}
        />
      </DialogComponent>
    </>
  );
};

export default connect(
  (state) => ({
    uploadedVideoList: state.video.get("uploadedVideoList"),
    popupClose: state.video.get("popupClose"),
    loadingAction: state.common.get("loadingAction"),
  }),
  {
    uploadVideo: Actions.video.uploadVideo,
    fetchVideo: Actions.video.fetchVideo,
    verifyToken: Actions.auth.verifyToken,
  }
)(VideoScreen);
