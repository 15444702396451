import { Box } from "@mui/material";
import React, { useState } from "react";
import { AnswerView, IconButtonComponent, RadioButtonComponent } from "../../atom";
import _ from "lodash";


export default ({ item, index , onclick=()=>{}, onPressDelete=()=>{}}) => {

    return (
        <Box className="admin-question-wrap">
            <Box className="admin-question">
                <Box>
                    <p style={{ fontFamily: "Montserrat, sans serif", fontWeight: 700, fontSize: "100%" }}>{`${index + 1}. ${_.get(item, 'question', '')}`}</p>
                </Box>
            </Box>
            <Box className="admin-question-btn-section">
                <Box className="admin-question-btn">
                    <IconButtonComponent btntitle={"View"} btnType={"viewIconbtn"} onclick={() => {
                        // setViewQuestion(true)
                        onclick()
                    }} />
                    <IconButtonComponent onclick={onPressDelete} btntitle={"Delete"} btnType={"deleteIconbtn"} />
                </Box>
            </Box>
        </Box>
    )
}
