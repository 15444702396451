import React from "react";
import { Box} from "@mui/material";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const TextIconButtonComponent = ({ onclick=()=>{}, icon, buttonStyleClass="btn btn-primary", btnText}) => {
    return (<Box>
         <button onClick={onclick} className={buttonStyleClass}><Box sx={{display:"flex", alignItems:"center"}}></Box><FontAwesomeIcon icon={icon}/> {btnText}</button>
    </Box>);
}

export default TextIconButtonComponent;