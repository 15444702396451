import React from "react";
import IconButton from '@mui/material/IconButton';
import { Box, Grid, Typography } from "@mui/material";
import StarRatingoComponent from "../../atom/Buttons/StarRating";
import TextButtonComponet from "../../atom/Buttons/TextButton";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from "react";
import { IMAGE_URL, getSourcePath, getText, onToast } from "../../../core/Constant";
import _ from "lodash";


const QuizMainCard = ({ data, length ,index, showFinish = false, attempt = false, onNext = () => { }, onBack = () => { }, answers = [] }) => {
  const [answer, setAnswer] = useState([]);

  useEffect(() => {
    const anw = _.get(answers, 'answer', []);
    if (anw.length > 0) {
      setAnswer(anw)
    }

  }, [answers, data])




  const handleChange = (event) => {
    const text = getText(event);
    setAnswer([text]);
    // localStorage.setItem("answer", event.target.value)
  }

  const handlechangeForm = (event) => {
    const text = getText(event);
    const isChecked = _.findIndex(answer, i => i == text) > -1
    if (!isChecked) {
      setAnswer([...answer, text])
    } else {
      const anw = _.filter(answer, i => i !== text);
      setAnswer(anw)
    }
  }

  const onClick = () => {
    const object = {
      id: data.id,
      type: data.type,
      answer
    }
    if (answer.length > 0) {
      onNext(object);
      setAnswer([])
    }
    else {
      const respons_json = '{"status_code": "0", "message":"Please Select Value to Continue" }';
      onToast('Select', JSON.parse(respons_json), false);
    }
  }

  return (
    <>

      <>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: 1, height: 'auto', minHeight: 300, justifyContent: 'center' }}>
          <Box sx={{ width: 1, display: 'flex', flexDirection: 'column' }}>
            <Typography color={"#4a6375"} fontSize={20} fontWeight={600}>
              {`${index + 1}. ${data?.question}`}
            </Typography>

            {data?.type == 2 ?

              <FormControl
                variant="outlined"
                margin="normal"
                component="fieldset"
                defaultValue={[]}
                onChange={handlechangeForm}
                style={{ marginTop: '20px' }}
              >
                <FormGroup style={{
                  display: 'grid',
                  marginTop: 10,
                  marginRight: 5,
                  gap: 5,
                  gridTemplateColumns: "repeat(2, 1fr)"
                }}>
                  {data?.answers?.map((item, index) => {
                    const isChecked = _.findIndex(answer, i => i == item.id) > -1
                    return (
                      <Box className='quiz-box'>
                        <FormControlLabel value={item.id} control={<Checkbox checked={isChecked} />} label={`${item?.answer}`} />
                      </Box>
                    )
                  })}
                </FormGroup>
              </FormControl>
              :
              <FormControl
                style={{ marginTop: '20px' }}
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  style={{
                    display: 'grid',
                    gap: 5,
                    gridTemplateColumns: "repeat(2, 1fr)"
                  }}
                  value={answer}
                  onChange={handleChange}
                >
                  {data?.answers?.map((item, index) => {
                    const isChecked = answer[0] == item.id
                    return (
                      <Box className='quiz-box'>
                        <FormControlLabel value={item.id} control={<Radio checked={isChecked} />} label={item.answer} />
                      </Box>
                    )
                  })}
                </RadioGroup>
              </FormControl>
            }


          </Box>
          {data?.image !== null || undefined ? <Box
            component="img"
            sx={{
              boxShadow: 2,
              height: 250,
              width: 250,
              marginLeft: 2
            }}
            alt="The house from the offer."
            src={getSourcePath(data?.image)}
          /> : <></>}

        </Box>
        {/* {attempt ?
          <>
            <p className="attempt-answer">Correct answer is <span style={{ color: '#28b882', fontWeight: 700 }}>Gotham</span></p>
            <p style={{ fontWeight: 800, color: "black" }} className="attempt-reason">Reason<span className="attempt-reason">New York City comprises 5 boroughs sitting where the Hudson River meets the Atlantic Ocean. At its core is Manhattan, a densely populated borough that’s among the world’s major commercial, financial and cultural centers. Its iconic sites include skyscrapers such as the Empire State Building and sprawling Central Park. Broadway theater is staged in neon-lit Times Square.</span></p>
          </>
          : <></>
        } */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          width: 1,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          {index == 0 ? null : <Box sx={{ width: 350 }}>
            <TextButtonComponet onButtonClick={onBack} classStyle="btn btn-secondary" text={"Back"} />
          </Box>}
          <Box sx={{ width: 350 }}>
            <TextButtonComponet onButtonClick={onClick} text={length-1 == index ? "Finish" : "Next"} />
          </Box>
        </Box>
      </>
      {/* //   )
        // })} */}
    </>

  );
}

export default QuizMainCard;