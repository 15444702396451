import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import StarRatingoComponent from "../../atom/Buttons/StarRating";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VideoListView } from '../../atom';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from "lodash"

const CourseListCard = ({ icon, onclick, size, courseData }) => {
  const [data, setData] = useState([])
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [hide, setHide] = useState(false)

  useEffect(() => {
    const list = _(courseData).groupBy("week").values().map(item => {
 
      return _(item).map(i=>{
        const day = i?.day;
        if(day==0){
          i ={...i, day:1000}
        }
       return i;
      }).groupBy('day').values().value()
    }).value();
    setDataList(list)
  }, [courseData])

  const onNavigatetoPreview = (value, item) => {
    const token = localStorage.getItem('token')
    if (token && token.length && token.length > 1 && value.content_type == 1) {
      navigate('/course-video', {
        state: { course: value, courselist: item }
      });
    }
    else if (token && token.length && token.length > 1) {
      navigate('/quiz-main', {
        state: { quizData: { id: value.content_link, duration: value.duration } }
      });
    }
    else if (!token) {
      navigate('/course-video-free', {
        state: { course: value, courselist: item }
      });
    }

  }

  const setOnHide = (item) => {
    if (hide == true) {
      setHide(true)
    }
    else {
      setHide(false)
    }
  }

  const CustomExpandText = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none"
          },
          ".expandIconWrapper": {
            display: "none"
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block"
          }
        }}
      >
        <div className="expandIconWrapper">
          <Typography sx={{
            fontFamily: 'Montserrat',
            fontSize: '16px',
            color: '#28b882', flex: 0.2
          }}>Hide Details</Typography>
        </div>
        <div className="collapsIconWrapper">
          <Typography sx={{
            fontFamily: 'Montserrat',
            fontSize: '16px',
            color: '#28b882', flex: 0.2
          }}>Show Details</Typography>
        </div>
      </Box>
    );
  };


  return (<Box sx={{
    width: '100%',
    alignSelf: 'center'
  }}>
    <div>
      {/* { Object.keys(courseData).forEach(function(item, index) { */}

      {/* }) */}
      {dataList.map((item, index) => {
       
        return (
          <Accordion sx={{ boxShadow: 0 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: '#28b882' }} />}
              aria-controls="panel1a-content"
              id={item[index]}
              exp
              sx={{ display: 'flex', flexDirection: 'row', width: 1, flex: 1 }}
              onClick={() => setOnHide()}
            >
              <Typography sx={{
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: '600',
                color: '#2d3945',
                flex: 0.8
              }}>{item[0][0].week}</Typography>
              {
                CustomExpandText()
              }
            </AccordionSummary>
            <AccordionDetails>
              <>
                {item.map((values, count) => {
                  return (
                    <>
                      {values.map((value, id) => {
                         let contenttype = 0;
                         if (value?.students_course_status?.length == 1) {
                           contenttype = 3
                         }
                         else {
                           contenttype = value?.content_type
                         }
                         return (
                           <VideoListView index={id} item={value} loked={value?.is_locked} contentType={contenttype} onPreviewClick={() => onNavigatetoPreview(value, item)} content={value?.content} heading={value?.day} />
       
                         )
                      })}
                    </>
                  )
                })}
              </>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>

  </Box>);
}

export default CourseListCard;