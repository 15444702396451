import React from "react";
import IconButton from "@mui/material/IconButton";
import { Box, Grid, Typography } from "@mui/material";
import StarRatingoComponent from "../../atom/Buttons/StarRating";
import { DurationButton } from "../../atom";

const CourseRatingCard = ({
  title,
  reviews,
  duration,
  durationtitle,
  price,
  pricetitle,
  rating = 3,
}) => {
  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item>
        <Grid container flexDirection={"column"} >
          <Grid item>
            <span
              style={{
                color: " #2d3945",
                fontSize: 22,
                fontWeight: 800,
                fontFamily: "Montserrat",
              }}
            >
              {title}
            </span>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <StarRatingoComponent readOnly={true} selectValue={rating} />
              </Grid>
              <Grid item>
                <p
                  style={{
                    color: "#4a6375",
                    fontFamily: "Montserrat",
                    marginLeft: 10,
                  }}
                >
                  {reviews} Reviews
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <DurationButton subtitle={duration} title={durationtitle} />
          </Grid>
          <Grid item>
            <DurationButton subtitle={price} title={pricetitle} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CourseRatingCard;
