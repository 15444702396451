import React, { useState, useEffect, useRef } from "react";
import { Box, Rating, Typography } from "@mui/material";
import TextInputComponent from "../../../component/atom/Inputs/TextInput";
import TextButtonComponet from "../../../component/atom/Buttons/TextButton";
import SignInForm from "../../../component/molecule/Forms/SignInForm";
import StarRatingoComponent from "../../../component/atom/Buttons/StarRating";
import { useNavigate } from "react-router-dom";
import { SidebarContainer } from "../../../component/organism";
import { CircularWithValueLabel, VideoPlayerComponent } from "../../../component/atom";
import { CourseViewCard } from "../../../component/molecule";
import { useLocation } from 'react-router-dom';
import { Actions } from "../../../core/modules/Actions";
import { connect } from "react-redux";
import { setTopLevelNavigator } from "../../../core/services/NavigationServicd";
import {IconButtonComponent} from "../../../component/atom";



const CourseVideoScreen = ({ videoStatus, setVideoCompleted, verifyToken,coursecatalog }) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(2);
  const [newcourse, setCourse] = useState()
  const [newcourseList, setNewCourseList] = useState()
  const [newlink,setLink] = useState();
  const [newwebinar,setWebinar] = useState(false)
  const [newcoursecatalog,setCourseCatalog] = useState([],)
  const location = useLocation();
  const [token, setToken]=useState(null)

  let course = location.state?.course;
  let link = location.state?.link;
  let webinar = location.state?.webinar;

  let courselist = location.state?.courselist;

  useEffect(() => {
    setCourse(course)
    setLink(link)
    setWebinar(webinar)
  }, [newcourse])

  useEffect(()=>{
    setCourseCatalog(coursecatalog)
  },[])

  const videoMarkCompleted = () => {
    setVideoCompleted({ course_content_id: course.id })
  };

  useEffect(()=>{
    const token = localStorage.getItem('token');
    setToken(token)
  },[])

  return (< >
    <Box
      sx={{
        width: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight:20,
        paddingTop:8
      }}
    >
      <Box sx={{
        width: 1
      }}>
              <Box>
            <IconButtonComponent onclick={() => navigate(-1)} btnType="backbtn" btnText="Back" />
        </Box>
        { 
        newwebinar ? <></> :
        <>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          width: 1,
          marginBottom: 2,
          justifyContent: 'space-between',
        }}>
          <Typography fontFamily={'Montserrat'} fontSize={22} fontWeight={800}>
            {newcoursecatalog.data?.name}
          </Typography>

        </Box>

<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
<Box sx={{ display: 'flex', flexDirection: 'column' }}>
  <Typography fontFamily={'Montserrat'} fontSize={20} fontWeight={800} color={'black'}>
    {course?.content}
  </Typography>
  <Typography fontFamily={'Montserrat'} fontSize={18} fontWeight={600} color={'#9eafbe'}>
    {course?.week}- Day 0{course?.day}
  </Typography>
</Box>
<CircularWithValueLabel text={course?.duration} progress={100}/>
</Box>
</>

        }
        <Box sx={{
          display: 'flex',
          alignSelf: 'center',
          width: 1,
          height: 1,
          marginTop: 5,

        }}>
          <VideoPlayerComponent videoUrl={webinar ? link : course?.content_link} />
          {/* <SampleVideoCard/> */}
        </Box>

      </Box>
      <Box sx={{ marginTop: '15%', width: 1 ,marginBottom:10}}>
      {token && token.length && !newwebinar  ?   <Box sx={{ width: 200, marginLeft: '75%' }}>
          <TextButtonComponet onButtonClick={videoMarkCompleted} text={'mark as completed'} />
        </Box>: null}
        {/* <p>Description</p>
        <p></p> */}

        {/* {courselist?.map((item, index) => {
          return (
            <>
              <CourseViewCard  contentType={item?.content_type} day={item?.day} content={item?.content} />
            </>
          )
        })} */}
      </Box>
    </Box>

  </>);
}

export default connect(
  state => ({
    coursecatalog: state.course.get('coursecatalog')

  }),
  {
    setVideoCompleted: Actions.course.setVideoCompleted,
    verifyToken: Actions.auth.verifyToken
  }
)(CourseVideoScreen); 
