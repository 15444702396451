import { IMAGES } from '../../../assets/Images';

export const adminScreens = [
    {
      icon: <img src={IMAGES.sideNavIconDashbaord} alt='sidenav-dashboard' className='sidenav-icon' />,
      name: "Dashboard", link: "/admin-dashboard"
    },
    {
      icon: <img src={IMAGES.sideNavIconStudent} alt='sidenav-student' className='sidenav-icon' />,
      name: "Students", link: "/admin-students"
    },
    {
      icon: <img src={IMAGES.sideNavIconUsers} alt='sidenav-users' className='sidenav-icon' />,
      name: "Users", link: "/admin-users"
    },
    {
      icon: <img src={IMAGES.sideNavIconCourseCatalog} alt='sidenav-courses' className='sidenav-icon' />,
      name: "Courses", link: "/admin-course"
    },
    {
      icon: <img src={IMAGES.sideNavIconQuiz} alt='sidenav-quiz' style={{ color: "#4A6375" }} className='sidenav-icon' />,
      name: "Quiz", link: "/admin-quiz"
    },
    {
      icon: <img src={IMAGES.sideNavIconForum} alt='sidenav-forum' className='sidenav-icon' />,
      name: "Forum", link: "/admin-forum"
    },
    {
      icon: <img src={IMAGES.sideNavIconMessage} alt='sidenav-message' className='sidenav-icon' />,
      name: "Message", link: "/admin-view-message"
    },
    {
      icon: <img src={IMAGES.sideNavIconVideo} alt='sidenav-video' className='sidenav-icon' />,
      name: "Video", link: "/admin-video"
    },
    {
      icon: <img src={IMAGES.sideNavIconReports} alt='sidenav-report' className='sidenav-icon' />,
      name: "Reports", more: [{ name: "Student Reports", link: "/admin-student-report" }, { name: "Payment Reports", link: "/admin-payment-report" }],
    },
    {
      icon: <img src={IMAGES.sideNavIconAnnouncement} alt='sidenav-announcement' className='sidenav-icon' />,
      name: "Announcement", link: "/admin-announcement"
    },
    {
      icon: <img src={IMAGES.sideNavIconProfile} alt='sidenav-profile' className='sidenav-icon' />,
      name: "Profile", link: "/profile"
    },
  ];

  export const studentScreens = [
    {
      icon: <img src={IMAGES.sideNavIconDashbaord} alt='sidenav-dashboard' className='sidenav-icon' />,
      name: "Dashboard", link: "/dashboard"
    },
    {
      icon: <img src={IMAGES.sideNavIconCourseCatalog} alt='sidenav-courses' className='sidenav-icon' />,
      name: "Course Catalog", link: "/course-catalog"
    },
    {
      icon: <img src={IMAGES.sideNavIconWebinar} alt='sidenav-webinar' className='sidenav-icon' />,
      name: "Webinar Archive", link: "/webinar"
    },
    {
      icon: <img src={IMAGES.sideNavIconQuiz} alt='sidenav-quiz' className='sidenav-icon' />,
      name: "Quiz", link: "/quiz"
    },
    {
      icon: <img src={IMAGES.sideNavIconForum} alt='sidenav-forum' className='sidenav-icon' />,
      name: "Forum", link: "/forum"
    },
    {
      icon: <img src={IMAGES.sideNavIconMessage} alt='sidenav-message' className='sidenav-icon' />,
      name: "Message", link: "/message"
    },
    {
      icon: <img src={IMAGES.sideNavIconAnnouncement} alt='sidenav-announcement' className='sidenav-icon' />,
      name: "Announcement", link: "/announcement"
    },
    {
      icon: <img src={IMAGES.sideNavIconProfile} alt='sidenav-profile' className='sidenav-icon' />,
      name: "Profile", link: "/profile"
    },
  ];