import React,{useState} from "react";
import { Box } from "@mui/material";
import ReactPlayer from 'react-player'
import CustomVideoControl from "./CustomVideoControl";

const VideoPlayerComponent = ({ videoUrl, tumbnailUrl,volume,muted }) => {
      const  [currentSeek,setCurrentSeek] = useState(0)
      const  [isPlay,setIsPlay] = useState(false)
      const  [volumeBar,setVolumeBar] = useState(100)
      const  [totalDurationOfVideo,setTotalDurationOfVideo] = useState(0)

const handlePause = async (e) => {
        setIsPlay(false)
        const data = {
            play: false,
            pause: true,
            isVideoPlayed: false,
            currentTime: currentSeek,
            actionBy: 'host'
        }
      }
            
const handlePlay = async (e) => {
        // if (totalDurationOfVideo === 0) {        
        //     setTotalDurationOfVideo(totalDurationOfVideo: this.hostVideo.current.getDuration())
        // }
        setIsPlay(true)
        const data = {
            play: true,
            pause: false,
            isVideoPlayed: true,
            currentTime: this.state.currentSeek,
            actionBy: 'host'
        }

    }
    
 const    handleSeekChange = async (e) => {
     setCurrentSeek( e.target.value )
        this.hostVideo.current.seekTo(e.target.value)
        const data = {
            currentTime: e.target.value,
            actionBy: 'host'
        }

    }
    
  const  handleVolumeChange = async (e) => {
    setVolumeBar(e.target.value)
        const data = {
            volume: e.target.value / 100,
            actionBy: 'host'
        }
    }
    return (<Box sx={{
        backgroundColor:'black',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        width:1,
        alignSelf:'center',
        marginBottom:2,
    }}>
        <ReactPlayer controls   config={{ file: { 
    attributes: {
      controlsList: 'nodownload'
    }
  }}}
width={'100%'} height={'100%'}  style={{minHeight:400}} url={videoUrl}  loop={true} volume={null} mute={true} />
        
        {/* future development */}
        {/* <CustomVideoControl
          currentSeek={currentSeek}
          playing={isPlay}
          volume={volumeBar}
          handlePause={(e) => handlePause(e)}
          handlePlay={(e) => handlePlay(e)}
          handleSeekChange={handleSeekChange}
          totalDurationOfVideo={totalDurationOfVideo}
          handleVolumeChange={handleVolumeChange} /> */}
    </Box>
    
//     const  [currentSeek,setCurrentSeek] = useState(0)
// const  [isPlay,setIsPlay] = useState(false)
// const  [volumeBar,setVolumeBar] = useState(100)
// const  [totalDurationOfVideo,setTotalDurationOfVideo] = useState(0)

// handlePause = async (e) => {
//   setIsPlay(false)
//   const data = {
//       play: false,
//       pause: true,
//       isVideoPlayed: false,
//       currentTime: currentSeek,
//       actionBy: 'host'
//   }
//   await db.collection('events')
//       .doc(this.props.WatchParty.firebaseEventId)
//       .update(data)
// }
    );
}

export default VideoPlayerComponent;


