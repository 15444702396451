import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarContainer, TabContainer } from "../../../component/organism";
import Divider from "@mui/material/Divider";
import {
  CourseContentCard,
  CourseRatingCard,
} from "../../../component/molecule";
import { CircularWithValueLabel, DurationButton, StarRatingoComponent, SubTitle } from "../../../component/atom";
import HeadingComponent from "../../../component/atom/Headings/Heading";
import { Actions } from "../../../core/modules/Actions";
import { connect } from "react-redux";
import _ from "lodash";
import {
  IMAGE_URL,
  onGetCountrySymble,
  onGetCurrencySymble,
} from "../../../core/Constant";
import { setTopLevelNavigator } from "../../../core/services/NavigationServicd";
import { IconButtonComponent } from "../../../component/atom";
const CourseDetailsScreen = ({
  getCousreDetails,
  coursedetails,
  course,
  verifyToken,
  getcourseProgress,
  courseprogress,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [courseID, setCourseId] = useState("");
  const [courseData, setCourseData] = useState();
  // const [newcourse, setNewCourse] = useState([]);
  const [progress, setProgress] = useState([]);
  const [isRegistered, setIsRegistered] = useState(true);
  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    const courseDetails = location?.state;
    setIsRegistered(courseDetails?.is_registered);
    setImgWidth(courseDetails?.is_registered ? 750 : 1150);
    setCourseData(courseDetails?.course);
    setCourseId(courseDetails?.course_id);
  }, [location, imgWidth]);

  useEffect(() => {
    getCousreDetails(courseID);
  }, [courseID]);

  useEffect(() => {
    getcourseProgress();
  }, []);

  useEffect(() => {
    const obj = _.find(courseprogress, (item) => item?.course_id == courseID);
    setProgress(obj);
  }, [courseprogress, courseID]);

  // useEffect(() => {
  //   setCourseData(coursedetails);
  //   setNewCourse(course);
  //   setItemData(coursedetails);
  // }, [itemData]);

  return (
    <>
      <Box className="main-screen-container">
        <Box sx={{ paddingLeft: 5 }}>
          <IconButtonComponent
            onclick={() => navigate(-1)}
            btnType="backbtn"
            btnText="Back"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: 1,
            mb: -5,
            padding: 5,
            height: "auto",
            justifyContent: "space-between",
          }}
        >
          <HeadingComponent
            text={"Course Details"}
            fontweigth={600}
            size={26}
            fontfamily={"Montserrat"}
          />
        </Box>
        {coursedetails.map((item, index) => {
          if (!item.is_active) {
            return null;
          }
          return (
            <>
              <Grid
                container
                justifyContent={"center"}
                spacing={1}
                alignItems={"center"}
              >
                <Grid item>
                  <Avatar
                    alt="I"
                    src={IMAGE_URL + item?.course_image}
                    sx={{ width: imgWidth, height: 400, borderRadius: 4 }}
                  />
                </Grid>
                {isRegistered ? (
                  <Grid item>
                    <Card
                      sx={{
                        minWidth: 400,
                        borderRadius: 5,
                        height: 400,
                        backgroundColor: "rgba(40, 184, 130, 0.15)",
                        boxShadow: 2,
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          flexDirection={"column"}
                          alignItems={"center"}
                          rowSpacing={3}
                          justifyContent={"center"}
                          sx={{ height: 400 }}
                        >
                          <Grid item>
                            <p
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontWeight: 800,
                              }}
                            >
                              Course progress
                            </p>
                          </Grid>
                          <Grid item>
                            <CircularWithValueLabel
                              text={progress?.progress + "%"}
                              progress={progress?.progres}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    {/* {isRegistered ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      flex: 0.3,
                      padding: 1,
                      maxWidth: 350,
                      borderRadius: "40px",
                      backgroundColor: "rgba(40, 184, 130, 0.15)",
                      boxShadow: 2,
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontWeight: 800,
                      }}
                    >
                      Course progress
                    </p>
                    <CircularWithValueLabel
                      text={progress?.progress + "%"}
                      progress={progress?.progres}
                    />
                  </Box>
                ) : null} */}
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  <Grid container justifyContent={'space-between'} pl={5} pr={5} mt={1}>
                    <Grid item>
                      <Grid container flexDirection={"column"}>
                        <Grid item>
                          <span
                            style={{
                              color: " #2d3945",
                              fontSize: 22,
                              fontWeight: 800,
                              fontFamily: "Montserrat",
                            }}
                          >
                            {item?.name}
                          </span>
                        </Grid>
                        <Grid item >
                          <Grid container >
                            <Grid item>
                              <StarRatingoComponent
                                readOnly={true}
                                selectValue={item?.averageRating}
                              />
                            </Grid>

                            <Grid item>
                              <p
                                style={{
                                  color: "#4a6375",
                                  fontFamily: "Montserrat",
                                  marginLeft: 10,
                                }}
                              >
                               {item?.totalRatingCount < 10 ? "0" + item?.totalRatingCount : item?.totalRatingCount} Reviews
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <DurationButton
                            subtitle={item.duration + " weeks"}
                            title={"Duration"}
                          />
                        </Grid>
                        <Grid item>
                          <DurationButton subtitle={"Price"} title={`${onGetCurrencySymble(
                    _.get(item, "currency"),
                    "GBP"
                  )} ${_.get(item, "price", "")}`} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  width: 1,
                  padding: 2,
                }}
              ></Box>

              {/* <Box sx={{ paddingLeft: 4, paddingRight: 2, maxWidth: 1100 }}>
                <CourseRatingCard
                  rating={item.averageRating}
                  pricetitle={"Price"}
                  price={`${onGetCurrencySymble(
                    _.get(item, "currency"),
                    "GBP"
                  )} ${_.get(item, "price", "")}`}
                  durationtitle={"Duration"}
                  duration={item.duration + " weeks"}
                  title={item.name}
                />
              </Box> */}

              <Box
                sx={{
                  maxWidth: 1100,
                  height: "auto",
                  minHeight: 500,
                  paddingLeft: 4,
                  paddingRight: 4,
                }}
              >
                <TabContainer data={course} />
              </Box>

              <Divider />
              <Divider />
            </>
          );
        })}
      </Box>
    </>
  );
};

export default connect(
  (state) => ({
    coursedetails: state.course.get("coursedetails"),
    course: state.course.get("course"),
    courseprogress: state.home.get("courseprogress"),
  }),
  {
    getCousreDetails: Actions.course.getCousreDetails,
    verifyToken: Actions.auth.verifyToken,
    getcourseProgress: Actions.home.getcourseProgress,
  }
)(CourseDetailsScreen);
