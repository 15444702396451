import React, { useEffect, useState, useRef } from "react";
import DashboardCard from "../../../component/molecule/Cards/DashboardCard";
import { IMAGES } from "../../../assets/Images";
import { Box, Grid } from "@mui/material";
import HeadingComponent from "../../../component/atom/Headings/Heading";
import { connect } from 'react-redux';
import { Navigate, useNavigate } from "react-router";
import { setTopLevelNavigator } from "../../../core/services/NavigationServicd";
import { BarChart, axisClasses } from "@mui/x-charts";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";


const DashboardAdminScreen = ({ adminDashboardData, getAdminDashboardData, verifyToken }) => {
  // const navigate = useNavigate();
  // const intervalRef = useRef(null);
  const [dataSet1, setData] = useState([])
  const [list, setList] = useState([])

  const [dashboardData, setDashboardData] = useState({});

  useEffect(() => {
    getAdminDashboardData()
    // verifyToken()
  }, [])

  useEffect(() => {
    setDashboardData(adminDashboardData);
    // const dataSet = _.values(_.get(adminDashboardData, 'countsByDay', []));
    // const keys = _(dataSet).keyBy('day').mapValues('count').value();
    var xContent = [];
    var yContent = [];
    const options = { weekday: 'long' };

    if (adminDashboardData.activeStudentsCount != undefined) {
      for (var i = 0; i < adminDashboardData.countsByDay.length; i++) {
        xContent.push(new Date(adminDashboardData.countsByDay[i].day).toLocaleString('en-US', options))
        yContent.push(adminDashboardData.countsByDay[i].count)
      }
      setList(yContent);
      setData(xContent);
    }


  }, [adminDashboardData]);

  const valueFormatter = (value) => `${value}`;

  return (
    <Box className="main-screen-container">
      <Box className="dashboard-main-container">
        <Box className="admin-dashboard-heading">
          <HeadingComponent text={"Dashboard"} fontfamily={"Montserrat"} fontweigth={600} size={40} />
        </Box>

        <Grid container justifyContent={"space-between"} alignItems={"center"} rowSpacing={1.5} spacing={1.5}>
          <Grid item md={6} xs={12} container spacing={5}>
            <Grid item xs={12}>
            <DashboardCard value={dashboardData?.activeStudentsCount} text="No of Students" icon={IMAGES.studentsIcon} />
            </Grid>
            <Grid item xs={12}>
            <DashboardCard value={dashboardData?.activeAdminCount} text="No of Users" icon={IMAGES.userIcon} />
            </Grid>
          </Grid>



          <Grid item md={6} xs={12} className="dashboard-inner-content">
          {dashboardData?.activeStudentsCount != undefined ? (<Box sx={{mt:2}}>
                <HeadingComponent text={"New Students for week"} fontfamily={"Montserrat"} fontweigth={600} size={25} />
                <BarChart
                  xAxis={[
                    {
                      id: 'barCategories',
                      data: dataSet1,
                      scaleType: 'band',
                      label: 'Day', valueFormatter
                    },
                  ]}
                  series={[
                    {
                      data: list,
                    },
                  ]}
                  width={600}
                  height={400}
                  viewBox={"0 100 600 400"}
                />
              </Box>) : (<></>)}
            {/* <Box className="dashboard-graph-section" maxWidth="sm">

             
            </Box> */}

          </Grid>

        </Grid>

      </Box>

    </Box>

  )
}

export default connect(
  state => ({
    adminDashboardData: state.home.get("adminDashboardData"),
  }),
  {
    // logIn: Actions.auth.logIn,
    getAdminDashboardData: Actions.home.getAdminDashboardData,
    verifyToken: Actions.auth.verifyToken
  },
)(DashboardAdminScreen);